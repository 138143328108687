#login {
    display: grid;
    grid-template-columns: auto 32.71%;
    grid-template-rows: minmax(100px, 20%) auto;
    min-width: 1050px;
    overflow: hidden;
    min-height: 100vh;
}

.login_left {
    height: 100%;
    grid-row-start: 1;
    grid-row-end: 3;
    background-image: url('../images/Picture2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.login_right {
    height: 550px;
    grid-column-start: 2;
    padding: 21px 37px 125px 37px;
    margin-bottom: 100px;
    margin: 0;
}

.logo_image {
    width: 150px;
    padding: 21px 0 0 37px;
}

.login .form-item>div {
    position: relative;
}

.login .form-item span.icons {
    position: absolute;
    left: 13px;
    width: 15px;
    height: 46px;
    display: block;
}

.login .form-item span.icons svg {
    position: absolute;
    top: 1px;
    left: -3px;
    width: 23px;
    height: 45px;
    display: block;
}

.userIcon {
    background: url('../images/user_icoin.png') no-repeat center;
    background-size: 100%;
}

.passwrodIcon {
    background: url('../images/password_icoin.png') no-repeat center;
    background-size: 100%;
}

.codeIcon {
    background: url('../images/lock_open.svg') no-repeat center;
    background-size: 100%;
}

.login .form-item span.icons.codeIcon {
    left: 10px;
    width: 20px;
    height: 48px;
}

.form-item {
    margin-top: 25px;
}

.login .form-control {
    width: 100%;
    padding-left: 40px;
    background: #F6F6F6;
    border-radius: 0;
    border: 1px solid lightgrey;
    font-size: 1rem;
    height: 46px;
}

.loginButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.error-main {
    width: 50%;
    position: absolute;
    top: 30vh;
    left: 25%;
}

@media screen and (max-width: 650px) {
    #login {
        grid-template-columns: 30% auto;
        grid-template-rows: 30% auto;
        height: auto;
        min-width: 200px;
    }

    .login_left {
        min-height: 50px;
        margin: 21px 37px 0 37px;
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .login_right {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}

@media screen and (max-width: 370px) {
    #login {
        min-width: 370px;
    }

    .logo_image {
        padding: 21px 0 0 10px;
        width: 100px;
    }

    .login_left {
        margin: 0;
    }

    .login_right {
        padding: 21px 10px 100px 10px;
    }
}