
.dme-utility-imageSetting img{
    max-height: 100px;
    width: auto;
}

.blockview-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
}

.blockview-cell{
    padding: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    text-align: center;
}

.blockview-cell .field-name {
    margin-top: 5px;
    font-size: 0.85rem;
    color: #333333;
}

.selected > .contenttype-image{
    display:inline-block
}

.blockview-cell:hover{
    opacity: 0.8;
    outline: 1px solid #cccccc;
}

.fieldtype-image-img{
    max-width: 100%;
    max-height: 150px;
    display:inline-block
}

.fieldtype-image{
    text-align: center;
}

.selected .fieldtype-image, .browse-selected {
    border: 1px solid #999999;
    cursor: pointer;
}

.selected .fieldtype-image:hover{
    
}

svg{
    vertical-align: unset !important;
}