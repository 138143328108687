.dashboard-layout {
  display: flex;
}

.content {
  margin-left: 250px; /* Should match the sidebar's width */
  margin-top: 40px; /* Should match the topbar's height */
  padding: 20px; /* Adjust as needed */
  width: calc(
    100% - 250px
  ); /* Adjust based on your sidebar width to ensure full coverage */
  overflow-y: auto;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px; /* Adjust the gap between cards as needed */
  margin-bottom: 20px; /* Extra space at the bottom if needed */
}

.search-bar {
  flex-grow: 1; /* Allows the search bar to expand */
}

.root ul {
  list-style: none;
  padding: 0;
}

.root ul li {
  display: inline-block;
}

.root ul li a {
  text-decoration: none;
  color: #999;
}

.root ul li a:hover,
.root ul li a.active {
  color: #333;
  background-color: rgb(223, 223, 223);
}

@media (max-width: 768px) {
  .content {
    margin-left: 0;
    padding: 10px;
  }
}
